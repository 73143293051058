import SectionH2 from "../components/SectionH2";
import Reviews from "./Reviews";

const About = () => {
  return (
    <section id="section-about">
      {/* <SectionH2>About</SectionH2> */}
      {/* <div></div> */}
      <SectionH2>Reviews</SectionH2>
      <Reviews />
    </section>
  );
};

export default About;
